/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
// import { Link } from 'react-router-dom';

const NetError = () => {
  /* css */
  const netErrorDivCSS = css({
    marginTop: '15%'
  })

  const netErrorTextCSS = css({
    color: '#FF0000',
    fontSize: '1.5rem',
    width: 'max-content',
    margin: '0 auto'
  })

  const homeButtonLinkCSS = css({
    textDecoration: 'none',
    width: '12rem',
    display: 'block',
    margin: '0 auto',
    marginTop: '3%'
  })

  const homeButtonCSS = css({
    width: '12rem',
    padding: '0.75rem',
    border: '1px solid #64aef4',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    fontSize: '0.85rem',
    textDecoration: 'none',
    transition: '200ms ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#64aef4'
    },
    '&:active': {
      backgroundColor: '#428cd2'
    }
  })

  return (
    <div css={netErrorDivCSS}>
      <p css={netErrorTextCSS}>😵 There was an error! Check the developer console for more details.</p>
      {/* <Link to="/" css={homeButtonLinkCSS}><button css={homeButtonCSS}>Home</button></Link> */}
      <a css={homeButtonLinkCSS} href='/'><button css={homeButtonCSS}>Home</button></a>
    </div>
  )
}

export default NetError
