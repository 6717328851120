/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './containers/Header.jsx'
import SingleBeneficiaryCheck from './containers/SingleBeneficiaryCheck.jsx'

function App () {
  const appCSS = css({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  })

  return (
    <Router>
      <div css={appCSS}>
        <Header />
        <Routes>
          <Route path='/' element={<SingleBeneficiaryCheck />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
