/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const ButtonsDiv = () => {
  /* css */
  const divCSS = css({
    width: '60%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '3%'
  })

  const buttonCSS = css({
    display: 'block',
    width: '12rem',
    padding: '0.75rem',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#64aef4',
    transition: '200ms ease-in-out',
    fontSize: '0.85rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#428cd2'
    },
    '&:active': {
      backgroundColor: '#206ab0',
      color: '#ffffff'
    },
    '@media screen and (max-width: 1024px)': {
      width: '8rem'
    }
  })

  return (
    <div css={divCSS}>
      <button type='submit' css={buttonCSS}>Submit</button>
    </div>
  )
}

export default ButtonsDiv
