/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import iaEELogo from '../assets/iaEELogo.png';

const Header = () => {
  /* css */
  const headerCSS = css({
    width: '100%',
    backgroundColor: '#093264',
    borderBottom: '1px solid grey',
    padding: '0',
    margin: '0',
    display: 'flex',
    justifyContent: 'space-between'
  });

  const iaEELogoCSS = css({
    width: '200px',
    margin: '10px'
  });

  const poweredByTextCSS = css({
    fontSize: '1rem',
    width: 'max-content',
    color: '#ffffff',
    lineHeight: '45px',
    margin: '10px',
    '@media screen and (max-width: 406px)': {
      fontSize: '0.9rem'
    }
  });

  const poweredByLinkCSS = css({
    textDecoration: 'underline',
    color: '#ffffff',
    '&:hover': {
      textDecoration: 'none'
    }
  });

  return (
    <header css={headerCSS} id="header">
      <a href='https://indusaction.org' target='_blank' rel='noreferrer'><img css={iaEELogoCSS} src={iaEELogo} alt='Indus Action Eligibility Engine logo' /></a>

      <p css={poweredByTextCSS}>Powered by <a css={poweredByLinkCSS} href='https://www.idinsight.org/' target='_blank' rel='noreferrer' className='poweredByLink'>IDinsight</a></p>
    </header>
  );
}

export default Header;
