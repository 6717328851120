/*
GTET = "Greater Than Equal To"
BPL = "Below Poverty Line"
IB = "In Between"
MT = "More Than"
LT = "Less Than"
LT1L = "Less Than 1 (one) Lakh"
APL = "Above Poverty Line"
MT5Y = "More Than Five Years"
LT3L = "Less Than 3 (three) Lakhs"
*/

/* Here the program definition (~ the criteria) must contain only
values that must be true in order for the proximity value to be 1.
For example, when checking whether a beneficiary is a government employee,
the definition must be isNotAGovtEmployee: true and not isGovtEmployee: false */

const programs = [
  {
    programName: 'Indira Gandhi Old Age Pension',
    programUuid: '1111guloh',
    eligibilityCriteria: {
      isAgeGTET60: true,
      isBPLCardHolder: true,
      isNotGettingAnotherPension: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Indira Gandhi National Widow Pension Scheme',
    programUuid: '1111guloo',
    eligibilityCriteria: {
      'isAgeIB39&60': true,
      isFemale: true,
      isBPLCardHolder: true,
      isWidowed: true,
      isNotGettingAnotherPension: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Indira Gandhi National Disability Pension Scheme',
    programUuid: '1111gulog',
    eligibilityCriteria: {
      'isAgeIB17&60': true,
      isDisabled: true,
      isBPLCardHolder: true,
      isDisabledMT80: true,
      isNotGettingAnotherPension: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Destitute Widow Pension',
    programUuid: '1111gulom',
    eligibilityCriteria: {
      'isAgeIB17&60': true,
      isFemale: true,
      doesNotHaveIncomeSrc: true,
      isWidowed: true,
      isFixedAssetLimitLT1L: true,
      isNotGettingAnotherPension: true,
      isNotAGovtEmployee: true,
      isNotSupportedByAPLRelative: true
    }
  },
  {
    programName: 'Destitute / Deserted Wives Pension',
    programUuid: '1111gulon',
    eligibilityCriteria: {
      'isAgeIB29&60': true,
      isFemale: true,
      doesNotHaveIncomeSrc: true,
      hasBeenDivorcedDesertedForMT5Y: true,
      isFixedAssetLimitLT1L: true,
      isNotGettingAnotherPension: true,
      isNotSupportedByAPLRelative: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Differently Abled Pension Scheme',
    programUuid: '1111gulop',
    eligibilityCriteria: {
      'isAgeIB17&60': true,
      isIndividualIncomeLT3L: true,
      isDisabled: true,
      'isDisabilityIB40&80': true,
      isNotGettingAnotherPension: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Un-married Women Pension',
    programUuid: '1111guloq',
    eligibilityCriteria: {
      'isAgeIB49&60': true,
      isFemale: true,
      doesNotHaveIncomeSrc: true,
      isUnmarried: true,
      isFixedAssetLimitLT1L: true,
      isNotGettingAnotherPension: true,
      isNotSupportedByAPLRelative: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: "Chief Minister's Uzhavar Pathukappu Thittam Old Age Pension",
    programUuid: '1111gulor',
    eligibilityCriteria: {
      isAgeGTET60: true,
      isLandlessLabourer: true,
      doesNotHaveIncomeSrc: true,
      isFixedAssetLimitLT1L: true,
      isNotGettingAnotherPension: true,
      isNotSupportedByAPLRelative: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Pension to Sri Lankan Refugees - OAP',
    programUuid: '1111gulos',
    eligibilityCriteria: {
      isAgeGTET60: true,
      isSriLankanRefugee: true,
      doesNotHaveIncomeSrc: true,
      isFixedAssetLimitLT1L: true,
      isNotGettingAnotherPension: true,
      isNotSupportedByAPLRelative: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Pension to Srilankan Refugees - DAPS',
    programUuid: '1111gulot',
    eligibilityCriteria: {
      'isAgeIB17&60': true,
      isSriLankanRefugee: true,
      isDisabled: true,
      isIndividualIncomeLT3L: true,
      'isDisabilityIB40&80': true,
      isFixedAssetLimitLT1L: true,
      isNotGettingAnotherPension: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Pension to Srilankan Refugees - DWPS',
    programUuid: '1111gulou',
    eligibilityCriteria: {
      'isAgeIB17&60': true,
      isFemale: true,
      isSriLankanRefugee: true,
      doesNotHaveIncomeSrc: true,
      isWidowed: true,
      isFixedAssetLimitLT1L: true,
      isNotSupportedByAPLRelative: true,
      isNotAGovtEmployee: true
    }
  },
  {
    programName: 'Pension to Srilankan Refugees DDWPS',
    programUuid: '1111gulov',
    eligibilityCriteria: {
      'isAgeIB29&60': true,
      isFemale: true,
      isSriLankanRefugee: true,
      doesNotHaveIncomeSrc: true,
      hasBeenDivorcedDesertedForMT5Y: true,
      isFixedAssetLimitLT1L: true,
      isNotSupportedByAPLRelative: true,
      isNotAGovtEmployee: true
    }
  }
]

export default programs
