/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import ScaleLoader from 'react-spinners/ScaleLoader'

const Loader = () => {
  /* css */
  const loaderDivCSS = css({
    width: 'max-content',
    margin: '0 auto',
    marginTop: '10%'
  })

  return (
    <div css={loaderDivCSS}>
      <ScaleLoader color='#487BC0' cssOverride={{ margin: '0 auto', marginTop: '5%' }} margin={3} height={78} width={16} />
    </div>
  )
}

export default Loader
