/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const sectionTitleCSS = css({
  fontSize: '1.1rem',
  width: 'max-content',
  margin: '0 auto',
  '@media screen and (max-width: 1024px)': {
    fontSize: '0.9rem'
  }
});

const SectionTitle = ({ title }) => {
  return (
    <h6 css={sectionTitleCSS}>{title}</h6>
  );
}

export default SectionTitle;
