/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-date-picker'
import { v4 as uuid } from 'uuid';
import ButtonsDiv from '../components/ButtonsDiv.jsx';
import Loader from '../components/Loader.jsx';
import NetError from '../components/NetError.jsx';
import Result from '../components/Result.jsx';
import SectionTitle from '../components/SectionTitle.jsx';
import programs from '../data/programs.js';
import { Auth } from 'aws-amplify';

const SingleBeneficiaryCheck = () => {
  
  /* component early logic */
  const [authToken, setAuthToken] = useState('');

  const amplifyConfig = () => {
    Auth.configure({
      mandatorySignIn: true,
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    });
  }

  useEffect(() => {
    try {
      amplifyConfig();

      let avniSignIn = async () => {
        const loginResponse = await Auth.signIn(process.env.REACT_APP_USERNAME, process.env.REACT_APP_PASSWORD);
        setAuthToken(loginResponse.signInUserSession.idToken.jwtToken);
        console.dir(loginResponse);
      }

      avniSignIn();
    } catch (error) {
      console.error('There was an error trying to sign in: ', error);
    }
  }, []);

  
  /* component style */
  const sectionCSS = css({
    marginTop: '3%',
    '@media screen and (max-width: 1024px)': {
      marginTop: '15%'
    }
  });

  const formCSS = css({
    width: '85%',
    margin: '0 auto',
    padding: '1%',
    '@media screen and (max-width: 1280px)': {
      width: '100%'
    }
  });

  const inputContainerCSS = css({
    width: '40%',
    padding: '2%',
    border: '0.2px solid #efefef',
    boxShadow: '0px 4px 4px hsla(100, 0%, 0%, 0.25)',
    margin: '0 auto',
    '@media screen and (max-width: 1024px)': {
      width: '45%',
      overflow: 'hidden'
    }
  });

  const inputLabelCSS = css({
    display: 'block',
    marginBottom: '0.85rem',
    width: 'max-content',
    fontWeight: 'normal',
    fontSize: '0.95rem',
    color: '#121212',
    '@media screen and (max-width: 1024px)': {
      fontSize: '0.9rem'
    }
  });

  const inputBoxCSS = css({
    padding: '0.5rem',
    width: '100%',
    fontSize: '1rem',
    backgroundColor: `#ffffff`,
    '&:focus': {
      outline: '1px solid #64aef4'
    },
    '@media screen and (max-width: 1024px)': {
      fontSize: '0.9rem'
    }
  });

  const flexboxCSS = css({
    width: '80%',
    margin: '0 auto',
    display: 'flex',
    marginTop: '2rem',
    justifyContent: 'space-evenly',
  });

  const optionCSS = css({
    backgroundColor: '#ffffff'
  });

  /* component logic */
  const { control, register, handleSubmit } = useForm();
  const [sectionAppear, setSectionAppear] = useState(true);
  const [loaderState, setLoaderState] = useState(false);
  const [networkErrorState, setNetworkErrorState] = useState(false);
  const [resultAppear, setResultAppear] = useState(false);
  const [result, setResult] = useState({});
  const [requestBody, setRequestBody] = useState({});

  const onSubmit = async (data) => {
    setSectionAppear(false);
    setLoaderState(true);

    const beneficiaryUuid = uuid().slice(0, 8);

    const {
      dob,
      gender,
      annualIndividualIncome,
      community,
      isDisabled,
      employmentType
    } = data;

    const ageCalculator = dateOfBirth => {
      if (dateOfBirth === '') {
        return null;
      }

      const dob = new Date(dateOfBirth);
      const monthDiff = Date.now() - dob.getTime();
      const ageDt = new Date(monthDiff);
      const year = ageDt.getUTCFullYear();
      const ageResult = Math.abs(year - 1970);

      return ageResult;
    }

    const age = ageCalculator(dob);

    let bplCardHolderStatus;

    if (annualIndividualIncome === 'below 72,000' || annualIndividualIncome === 'no income source') {
      bplCardHolderStatus = true
    } else if (annualIndividualIncome === '') {
      bplCardHolderStatus = null
    } else {
      bplCardHolderStatus = false
    }

    const requestBody = {
      beneficiaries: [
        {
          beneficiaryUuid,
          doesNotHaveIncomeSrc: annualIndividualIncome === 'no income source' ? true : annualIndividualIncome === '' ? null : false,
          hasBeenDivorcedDesertedForMT5Y: null,
          isAgeGTET60: age === null ? null : age >= 60,
          'isAgeIB17&60': age === null ? null : age > 17 && age < 60,
          'isAgeIB29&60': age === null ? null : age > 29 && age < 60,
          'isAgeIB39&60': age === null ? null : age > 39 && age < 60,
          'isAgeIB49&60': age === null ? null : age > 49 && age < 60,
          isBPLCardHolder: bplCardHolderStatus,
          'isDisabilityIB40&80': null,
          isDisabled: isDisabled === 'yes' ? true : isDisabled === '' ? null : false,
          isDisabledMT80: null,
          isFemale: gender === '' ? null : gender === 'female',
          isFixedAssetLimitLT1L: null,
          isIndividualIncomeLT3L: annualIndividualIncome === '' ? null : annualIndividualIncome !== 'above 3 lakhs',
          isLandlessLabourer: community === 'landless labourer' ? true : community === '' ? null : false,
          isNotAGovtEmployee: employmentType === '' ? null : employmentType !== 'government employee',
          isNotGettingAnotherPension: null,
          isNotSupportedByAPLRelative: null,
          isSriLankanRefugee: community === 'sri lankan refugee' ? true : community === '' ? null : false,
          isUnmarried: null,
          isWidowed: null
        }
      ],
      programs
    }

    await axios({
      method: 'post',
      url: process.env.REACT_APP_API_ENDPOINT,
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      data: JSON.stringify(requestBody)
    })
      .then((response) => {
        setLoaderState(false);
        setResultAppear(true);
        setResult(response.data);
        setRequestBody(requestBody);
      })
      .catch((error) => {
        console.error(`There was an error: ${error}`)
        setLoaderState(false)
        setNetworkErrorState(true)
      });
  }

  /* component markup */
  return (
    <div>
      {sectionAppear &&
        <div css={sectionCSS}>
          <SectionTitle title="Enter your basic details" />

          <form css={formCSS} onSubmit={handleSubmit(onSubmit)}>

            <div css={flexboxCSS}>
              <div css={inputContainerCSS}>
                <label css={inputLabelCSS}>What is your date of birth?</label>
                <Controller
                  css={inputBoxCSS}
                  control={control}
                  name="dob"
                  rules={{ required: true }} //optional
                  render={ ({
                    field: { onChange, name, value },
                  }) => (
                    <DatePicker onChange={onChange} value={value} />
                  )}
                />
              </div>

              <div css={inputContainerCSS}>
                <label css={inputLabelCSS}>What is your gender status?</label>
                <select
                  css={inputBoxCSS}
                  {...register('gender', {})}
                >
                  <option css={optionCSS} value=''>Select your gender status</option>
                  <option css={optionCSS} value='male'>Male</option>
                  <option css={optionCSS} value='female'>Female</option>
                  <option css={optionCSS} value='other'>Other</option>
                </select>
              </div>
            </div>

            <div css={flexboxCSS}>
              <div css={inputContainerCSS}>
                <label css={inputLabelCSS}>How much is your annual individual income (in Rupees)?</label>
                <select
                  css={inputBoxCSS}
                  {...register('annualIndividualIncome', {})}
                >
                  <option css={optionCSS} value=''>Select your income</option>
                  <option css={optionCSS} value='below 72,000'>Below 72,000</option>
                  <option css={optionCSS} value='between 72,000'>Between 72,000 and 3 lakhs</option>
                  <option css={optionCSS} value='above 3 lakhs'>Above 3 lakhs</option>
                  <option css={optionCSS} value='no income source'>No income source</option>
                </select>
              </div>

              <div css={inputContainerCSS}>
                <label css={inputLabelCSS}>Which is your community?</label>
                <select
                  css={inputBoxCSS}
                  {...register('community', {})}
                >
                  <option css={optionCSS} value=''>Select your community</option>
                  <option css={optionCSS} value='sri lankan refugee'>Sri Lankan refugee</option>
                  <option css={optionCSS} value='landless labourer'>Landless labourer</option>
                  <option css={optionCSS} value='not applicable'>Not applicable</option>
                </select>
              </div>
            </div>
            <div css={flexboxCSS}>
              <div css={inputContainerCSS}>
                <label css={inputLabelCSS}>Are you disabled?</label>
                <select
                  css={inputBoxCSS}
                  {...register('isDisabled', {})}
                >
                  <option css={optionCSS} value=''>Select an answer</option>
                  <option css={optionCSS} value='yes'>Yes</option>
                  <option css={optionCSS} value='no'>No</option>
                </select>
              </div>

              <div css={inputContainerCSS}>
                <label css={inputLabelCSS}>What is your employment type?</label>
                <select
                  css={inputBoxCSS}
                  name='employmentType'
                  {...register('employmentType', {})}
                >
                  <option value=''>Select an answer</option>
                  <option css={optionCSS} value='government employee'>Government Employee</option>
                  <option css={optionCSS} value='private employee'>Private Employee</option>
                  <option css={optionCSS} value='self-employed'>Self-employed</option>
                  <option css={optionCSS} value='unemployed'>Unemployed</option>
                </select>
              </div>
            </div>

            <ButtonsDiv />

          </form>
        </div>}

      {loaderState && <Loader />}

      {networkErrorState && <NetError />}

      {resultAppear && <Result result={result} requestBody={requestBody} token={authToken} />}
    </div>
  );
}

export default SingleBeneficiaryCheck;