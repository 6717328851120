/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import SectionTitle from './SectionTitle.jsx';
import { useState } from 'react';
import axios from 'axios';
import { BsArrowUpCircle as ToTheTop } from "react-icons/bs";
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const Result = ({ result, requestBody, token }) => {
  /* component state variables */
  const { register } = useForm();
  const [programResultsArr, setProgramResultsArr] = useState(result[0].programEligibility);
  const [allCards, setAllCards] = useState(true);

  /* component style */
  const resultSection = css({
    marginTop: '2%'
  });

  const schemesMenu = css({
    width: '75%',
    margin: '0 auto',
    marginTop: '1%',
    border: '1px solid grey',
    padding: '0.5%'
  });

  const menuLink = css({
    color: `#428cd2`,
    textDecoration: `underline`,
    '&:hover': {
      textDecoration: `none`,
      cursor: `pointer`
    }
  });

  const pipe = css({
    paddingInline: `0.5%`,
    color: `black`,
    textDecoration: `none`,
    '&:hover': {
      cursor: `default`,
      textDecoration: `none`
    }
  });

  const cardsContainer = css({
  });

  const resultCard = css({
    background: 'hsla(215, 49%, 52%, 0.07)',
    width: '75%',
    margin: '0 auto',
    marginTop: '2%',
    transition: '200ms ease-in-out',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.5)'
    }
  });

  const resultCardHeader = css({
    backgroundColor: '#062246',
    width: '100%',
    padding: '1%'
  });

  const programName = css({
    color: '#ffffff',
    fontWeight: 'bold',
    textAlign: 'center'
  });

  const resultCardBody = css({
    width: '100%',
    padding: '2%',
    display: 'flex',
    justifyContent: 'space-evenly'
  });

  const proximitySection = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  });

  const proximityScore = css({
    fontSize: '1.5rem',
    textAlign: 'center',
    fontWeight: 'bold'
  });

  const criteriaSection = css({
    minWidth: '75%',
    maxWidth: '75%'
  });

  const homeButtonLink = css({
    textDecoration: 'none',
    width: '12rem',
    display: 'block',
    margin: '0 auto',
    marginTop: '3%',
    marginBottom: '2%'
  });

  const homeButton = css({
    width: '12rem',
    padding: '0.75rem',
    border: '1px solid #64aef4',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    fontSize: '0.85rem',
    textDecoration: 'none',
    transition: '200ms ease-in-out',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#64aef4'
    },
    '&:active': {
      backgroundColor: '#428cd2'
    }
  });

  const selectField = css({
    display: 'block',
    marginBottom: '2%',
    width: '100%',
    padding: '1.5%',
    fontSize: '0.9rem',
    backgroundColor: '#ffffff'
  });

  const selectOption = css({
    fontSize: '0.9rem',
    backgroundColor: '#ffffff'
  });

  const toTheTop = css({
    position: `fixed`,
    bottom: `10px`,
    right: `10px`,
    display: `block`,
    width: `50px`,
    height: `50px`,
    color: `#64aef4`,
    '&:hover': {
      cursor: 'pointer',
      color: '#428cd2'
    },
    '&:active': {
      color: '#206ab0'
    }
  });

  /* component logic */
  const keyDictionary = {
    doesNotHaveIncomeSrc: 'Does not have income source',
    hasBeenDivorcedDesertedForMT5Y: 'Has been divorced/deserted for more than 5 yrs',
    isAgeGTET60: 'Age >= 60',
    'isAgeIB17&60': 'Age in between 17 & 60',
    'isAgeIB29&60': 'Age in between 29 & 60',
    'isAgeIB39&60': 'Age in between 39 & 60',
    'isAgeIB49&60': 'Age in between 49 & 60',
    isBPLCardHolder: 'BPL card holder',
    'isDisabilityIB40&80': 'Disability between 40 and 80 percent',
    isDisabled: 'Is disabled',
    isDisabledMT80: 'Disabled more than 80 percent',
    isFemale: 'Is female',
    isFixedAssetLimitLT1L: 'Fixed asset limit < 1 lakh',
    isIndividualIncomeLT3L: 'Individual income < 3 lakhs',
    isLandlessLabourer: 'Is landless labourer',
    isNotAGovtEmployee: 'Is not a govt. employee',
    isNotGettingAnotherPension: 'Is not availing another pension',
    isNotSupportedByAPLRelative: 'Is not supported by APL relative',
    isSriLankanRefugee: 'Is Sri Lankan refugee',
    isUnmarried: 'Is unmarried',
    isWidowed: 'Is widowed'
  }

  const stringCleaner = string => {
    const strArray = string.split(', ');
    const cleanStrArray = strArray.map(strArrayElement => keyDictionary[strArrayElement]);

    return cleanStrArray.join(', ');
  }

  const handleChange = async (event) => {
    if (event.target.name === 'isDisabilityIB40&80' && event.target.value === 'true') {
      requestBody.beneficiaries[0][`isDisabledMT80`] = false;
    } else if (event.target.name === 'isDisabledMT80' && event.target.value === 'true') {
      requestBody.beneficiaries[0][`isDisabilityIB40&80`] = false;
    }
    requestBody.beneficiaries[0][`${event.target.name}`] = event.target.value === 'true';

    await axios({
      method: 'post',
      url: process.env.REACT_APP_API_ENDPOINT,
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: JSON.stringify(requestBody)
    })
    .then((response) => {
      setAllCards(true);
      setProgramResultsArr(response.data[0].programEligibility);
    })
    .catch((error) => {
      console.error(`There was an error: ${error}`);
    });
  }

  const sortedArray = programResultsArr.sort((a, b) => Number.parseFloat(b.proximityScore) - Number.parseFloat(a.proximityScore));

  /* component markup */
  return (
    <div>
      <div css={resultSection}>

        <SectionTitle title="Your eligibility for different pension schemes" />

        <div css={schemesMenu}>
          <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Menu (sorted by rank of eligibility)</p>
          {
            sortedArray.map((program, index) => {
              return (
                <Link to={program.programUuid} smooth={true}><span css={menuLink}>{(Number.parseInt(index, 10) + 1) + ". " + program.programName}<span css={pipe}>{
                  sortedArray.findIndex(element => element === program) !== (sortedArray.length - 1) ? '|' : null
                  }</span></span></Link>
              )
            }
            )
          }
        </div>

        <div css={cardsContainer}>
          {allCards && sortedArray.map(program => {
            const proxScore = program.proximityScore
            const missingData = program.missingData
            const missingDataArr = missingData.split(', ')

            return (
              <div key={program.programUuid} css={resultCard} id={program.programUuid}>

                <div css={resultCardHeader}>
                  <p css={programName}>{program.programName}</p>
                </div>

                <div css={resultCardBody}>
                  <div css={proximitySection}>
                    <p><b>Eligibility:</b></p>
                    <p
                      css={proximityScore}
                      style={{
                        color: `${proxScore < 0.4 ? 'red' : proxScore > 0.4 && proxScore < 0.6 ? 'orange' : 'lime'}`
                      }}
                    >{proxScore < 0 ? "Not Eligible" : 
                      proxScore >= 0 && proxScore < 0.4 ? "Low" : 
                      proxScore >= 0.4 && proxScore < 0.6 ? "Medium" : 
                      proxScore >= 0.6 && proxScore < 1 ? "High": "Eligible"}
                    </p>
                  </div>

                  <div css={criteriaSection}>
                    <p><b>Passed:</b> {stringCleaner(program.criteriaPassed)}</p>
                    <p style={{ marginTop: '1%' }}><b>Failed:</b> {stringCleaner(program.criteriaFailed)}</p>
                    <hr />
                    <p style={{ marginTop: '1%', marginBottom: '0.5%' }}>Add the following missing data (if any): </p>

                    <form onChange={handleChange}>
                      {allCards &&
                        missingDataArr.map((missingData, index) => {
                          if (missingData === '' || index === '') {
                            return null
                          } else {
                            return (
                              <div key={index}>
                                <label>{keyDictionary[missingData]}</label><br />
                                <select css={selectField} {...register(`${missingData}`)}>
                                  <option value='' css={selectOption}>Select answer</option>
                                  <option value='true' css={selectOption}>True</option>
                                  <option value='false' css={selectOption}>False</option>
                                </select>
                              </div>
                            )
                          }
                        })}
                    </form>

                  </div>
                </div>
              </div>
            )
          })}
        </div>

      </div>

      <a css={homeButtonLink} href='/'><button css={homeButton}>Home</button></a>

      <Link to="header" smooth={true}><ToTheTop css={toTheTop} /></Link>

    </div>
  );
}

export default Result;